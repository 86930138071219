<template>
    <div>
        <SignInForm />
    </div>
</template>

<script>
import SignInForm from '@/components/SignInForm.vue'
export default {
  components: {
    SignInForm
  }
}
</script>

<template>
    <div>
        <b-container>
            <section class="signin">
                <div class="sector">
                    <form :class="getDisabled ? 'disabled-element' : ''"  @submit.prevent="signIn" class="login-form">
                        <h2 class="form-title">Sign in to your account</h2>
                        <p v-if="getError" class="custom-error"> {{ getError }} </p>
                        <p v-if="signInForm.localError" class="custom-error">{{ signInForm.localError }}</p>
                        <div class="form-element">
                            <label for="signInEmail" class="form-label">E-mail address</label>
                            <input :disabled="getDisabled" v-model.trim="signInForm.signInEmail" @blur="$v.signInForm.signInEmail.$touch()" id="signInEmail" class="form-input" type="email" placeholder="Enter e-mail address" :class="[{'input-error' : $v.signInForm.signInEmail.$error }, getDisabled ? 'disabled-element' : '' ]" />
                            <div v-if="$v.signInForm.signInEmail.$error">
                                <p v-if="!$v.signInForm.signInEmail.required" class="custom-error">E-mail address is required</p>
                                <p v-else-if="!$v.signInForm.signInEmail.email" class="custom-error">E-mail address is invalid</p>
                                <p v-else-if="!$v.signInForm.signInEmail.maxLength" class="custom-error"> E-mail address must not exceed 50 characters</p>
                            </div>
                        </div>
                        <div class="form-element">
                            <label for="signInPassword" class="form-label">Password</label>
                            <input :disabled="getDisabled" v-model.trim="signInForm.signInPassword" @blur="$v.signInForm.signInPassword.$touch()" id="signInPassword" class="form-input" type="password" placeholder="Enter password" :class="[{'input-error' : $v.signInForm.signInPassword.$error }, getDisabled ? 'disabled-element' : '' ]" />
                            <div v-if="$v.signInForm.signInPassword.$error">
                                <p v-if="!$v.signInForm.signInPassword.required" class="custom-error">Password is required</p>
                                <p v-else-if="!$v.signInForm.signInPassword.minLength" class="custom-error"> Minimal password length is 8 characters</p>
                            </div>
                        </div>
                        <button :disabled="getDisabled" class="submit-button" type="submit">Sign In</button>
                        <b-row>
                            <b-col cols="12" sm="6">
                                <p class="form-text form-text--small">Don't have account yet? <router-link to="/signup" class="form-link">Sign up</router-link></p>
                            </b-col>
                            <b-col cols="12" sm="6" class="custom-align">
                                <router-link to="/reset-password" class="form-link">Forgot your password?</router-link>
                            </b-col>
                        </b-row>
                    </form>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import {
  email,
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      signInForm: {
        signInEmail: '',
        signInPassword: '',
        localError: null
      }
    }
  },
  validations: {
    signInForm: {
      signInEmail: {
        required: required,
        email: email,
        maxLength: maxLength(50)
      },
      signInPassword: {
        required: required,
        minLength: minLength(8)
      }
    }
  },
  methods: {
    signIn () {
      this.$store.dispatch('setDisabled')
      this.$v.signInForm.$touch()
      if (!this.$v.signInForm.$invalid) {
        this.$store.dispatch('signIn', { email: this.signInForm.signInEmail, password: this.signInForm.signInPassword })
          .then(() => {
            this.scrollToTop(500)
          })
          .catch(error => {
            this.signInForm.localError = error.message
            this.$store.dispatch('clearDisabled')
          })
      } else {
        this.scrollToTop(500)
        this.$store.dispatch('clearDisabled')
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    }
  },
  computed: {
    ...mapGetters([
      'getError',
      'getDisabled'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabled')
  }
}
</script>
